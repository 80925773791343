import logo from "./logo.svg";
import "./App.css";
import Column1 from "./components/Column1";
import Column2 from "./components/Column2";

function App() {
  return (
    <div className="App">
      <Column1 />
      <Column2 />
    </div>
  );
}

export default App;
