import "./css/Column2.css";

function Column2() {
  return (
    <div className="Column2">
      <header className="Column2-header">
        <p className="foundation-name">
          श्री. कन्हैयालाल बहुउद्देशीय संस्था रोहोड ता. साक्री ,जि. धुळे
        </p>
        <p>डॉ .मनीष जे सूर्यवंशी</p>
        <p>MBBS MUMBAI UNIVERSITY</p>
      </header>
      <div className="Column2-body">
        <hr className="hr-clm2"></hr>
        <p>
          नोंदणी क्र.१८६० नुसार ६९४९/धुळे संस्था का. ता. २/२/२००५ मुंबई
          सार्वजनिक विश्वस्थ अधिनियम १९५० अन्वये एफ ६९४७/धुळे ता. २२/२/२००६
          <br></br>
          <strong>संस्थेचे कार्य:-</strong>
          <br></br>
          १)आरोग्य सेवा - मोफत मोतीबिंदू शिबीर,गर्भ निरोधक साहित्य,कशोरवयीन
          मुलींना व महिलां ना सैनटरी पॅड वाटप,सि कलसेल तपा सणी व औषधोपचार.
          संसर्ग रोग व गुप्त रोग, एड्स, एच आय व्ही रोग प्रतिबंध उपचार.
          <br></br>
          २)पशुसेवा प्रकल्प<br></br>
          ३)महिला विकास प्रकल्प<br></br>
          ४)शैक्षणि क का र्य- संस्थेने २००८-०९ वर्षा पा सून प्रा थमि क इंगलि श
          मेडियम स्कुल माध्यमिक व सायन्स कॉलेज आदिवा सीविद्यार्थांना निवासी मोफत
          शिक्षण देणारी महाराष्ट्रातून पहिली संस्था आहे.
          <br></br>
          <strong>संस्थेचे कार्य:-</strong>
          <br></br>
          १)<strong>शासकीय नियुक्ती सदस्य-</strong> आदि वा सी आश्रम शाळा व वस्ती
          गृह, विद्यार्थी मृत्यु, सामाजिक आढावा समिती धुळे प्रकल्प सन २०१८ पासून
          आजतागायत
          <br></br>
          २)<strong>शासकीय नियुक्ती सदस्य</strong> कन्व्हर्जन समिती - अनुसूचित
          जमा ती व इतर पारंपारिक वननिवासी (वनहक्काची मान्यता ) अधिनियम २००६,
          नियम २००८ व सुधारित नियम २०१२ अंतर्गत कमल ३(१) नुसार सामुहिक वन हक्क
          गावाचे संवर्धन व व्यवस्था पन करण्या करिता तालुका स्तरीय कन्व्हर्जन
          समिती सदस्य.
          <br></br>
          ३)पंतप्रधान वनधन योजना म्हणुन तंज्ञ संस्था (बारीपाडा ,चावडीपाडा
          ,मोहगांव वनधन केंद्र).
          <br></br>
          ४)संस्थेच्या माध्यमातुन सामुदायिक वनहक्क एकूण ४४ गावांना मिळुन देण्यात
          महत्वाची भूमिका धुळे जिल्ह्या तून बजावली आहे. एकूण क्षेत्र १२०००
          हेक्टर संस्थेच्या माध्यमातून मिळवून देण्यात आली व कलम ३(१)(ग)
          गौनवानोत्पादानावरील हक्क,कलम ३(१)(झ) वनाचे संरक्षण व
          संवर्धन,पुननिर्माण व व्यवस्था पन करण्या चा हक्क,कलम ३(१)(ट) जैविक
          विविधतेत प्रवेश मिळण्या चा हक्क,कलम ३(१)(ठ) अन्य पारंपारिक हक्क.
        </p>
      </div>
    </div>
  );
}

export default Column2;
