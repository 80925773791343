import "./css/Column1.css";
import logo from "./img/logo.png";
import phone_icon from "./img/phoneicon.png";
import mail_icon from "./img/mailicon.png";

function Column1() {
  return (
    <div className="Column1">
      <div className="Column1-header">
        <img src={logo} className="logo"></img>
      </div>
      <hr className="hr-clm1"></hr>
      <div className="Column1-body">
        <p className="contact">
          <img src={phone_icon}></img>८६०५९२४१२२
        </p>
        <p className="mail">
          <img src={mail_icon}></img>
          <p className="mail-text">manishsuryawanshi5555@gmail.com</p>
        </p>
        <p>
          श्री .कन्हैयालाल बहुउद्देशीय <br></br>
          संस्था अध्यक्ष- डॉ. मनीष जे सूर्यवंशी<br></br>
          MBBS,Mumbai
        </p>
        <p>जन्म ता. २१/०१/१९७०</p>
        <p>अनुसूचित जमाती (ST)</p>
      </div>
    </div>
  );
}

export default Column1;
